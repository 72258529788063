import { Controller } from 'stimulus'
import Swiper, {Pagination, EffectFade} from 'swiper'

Swiper.use([Pagination, EffectFade])

export default class extends Controller {
  static targets = ['slider', 'pagination']

  connect() {
    let image = this.sliderTarget.querySelector('img');
    image.addEventListener('load', this.initSlider.bind(this));
    window.addEventListener('load', this.initSlider.bind(this));
  }

  /**
   * Initialize the mobile slider -
   * This will initialize the slider with the fade effect.
   * @returns {void}
   */
  initSlider() {
    if (this.swiper) {
      this.swiper.update();
    } else {
      this.swiper = new Swiper(this.sliderTarget, {
        slidesPerView: 1,
        centeredSlides: true,
        speed: 800,
        effect: 'fade',
        autoHeight: true,
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: this.paginationTarget,
          clickable: true,
        },
      })
    }
  }
}
